const fullscreenButtonClass = "ex-model3d-fullscreen__active"

document.querySelectorAll('.ex-model3d').forEach((element) => {
  element.querySelector('.ex-model-viewer__controls').addEventListener('click',
      () => toggleFullScreen(element.querySelector('.ex-model3d__3d-content')))

  element.querySelector('.ex-model-viewer').addEventListener('poster-dismissed', () => {
    element.querySelector('.ex-model-viewer__controls').style.display = 'block';
  })

  element.querySelector('.ex-model-viewer').addEventListener('click', () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'ga4Event',
      event_name: 'image_navigation',
      image_navigation : {
        type: '3D Model',
        item: 'Interaction',
        module: '3D Model',

        event_category: 'Navigation',
        event_action: '3D Model',
        event_label: 'Interaction',
        message_key: undefined,
        message_type: undefined
      }
    });
  }, { once: true });

})

function toggleFullScreen(element) {
  try {
    element.classList.toggle(fullscreenButtonClass);
  } catch (e) {
    console.log("toggleFullScreen: " + e);
  }
}

document.addEventListener('keydown', e => {
  if (e.key === "Escape") {
    try {
      document.querySelector('.ex-model3d__3d-content').classList.remove(fullscreenButtonClass);
    } catch (e) {
      console.log("addEventListener: " + e);
    }
  }
});